.ql-editor {
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	/* font-size: larger; */
}

.ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 100%;
	object-fit: contain; /* Adjust based on how you want the image to fit */
	max-width: 550px;
}

.cursor {
	cursor: pointer;
}
