/* 
@media print {
	* {
		color-adjust: exact !important ;
		-webkit-print-color-adjust: exact !important ;
		-print-color-adjust: exact !important ;
	}
}

@media print {
	.page-break {
		display: block !important ;
		page-break-before: auto !important ;
	}
}

@page {
}

@page {
	size: 250mm 297mm !important;
} */

@media print {
	.page-break {
		/* display: block !important ; */
		page-break-before: auto !important ;
		overflow: hidden;
	}
}

body {
	background: rgb(255, 255, 255);
}
page[size="A4"] {
	background: white;
	width: 21cm;
	height: 29.7cm;
	display: block;
	margin: 0 auto;
	padding: 0;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
@media print {
	body,
	page[size="A4"] {
		margin: 0;
		box-shadow: 0;
		padding: 0px;
		align-items: center;
		justify-content: center;
		color-adjust: exact !important ;
		-webkit-print-color-adjust: exact !important ;
		-print-color-adjust: exact !important ;
		/* page-break-before: auto !important ; */
	}
}
