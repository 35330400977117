/* .ql-editor {
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	padding: 0px;
	margin: 0px;
	overflow: visible;
}

.ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 70%;
	right: 0;

	object-fit: contain;
}

.cursor {
	cursor: pointer;
}

.ql-editor h1 {
	color: #eef2fc;
	font-size: xx-large;
}

.ql-editor h2 {
	color: #eef2fc;
	font-size: x-large;
	opacity: 0.9;
}

.ql-editor p {
	color: #eef2fc;
	font-size: medium;
	align-items: flex-end;
} */

.qlpreseditor h1 h2 p {
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc !important;
	padding: 0px;
	margin: 0px;
	font-size: 20px;
}

.qlpreseditor h1 {
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	/* font-size: larger; */
	font-size: var(--h1);
}

.qlpreseditor h1 strong {
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	/* font-size: larger; */
}

.qlpreseditor h2 strong {
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	background-image: linear-gradient(to right, #da47bc, #f78748);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.qlpreseditor h2 {
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	background-image: linear-gradient(to right, #da47bc, #f78748);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.qlpreseditor h1 strong {
	padding: 0px;
	margin: 0px;
	font-size: 23px;
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
}

.qlpreseditor a {
	font-size: 12px;
	color: #000;
	padding: 10px;
	border-radius: 10px;
	background-color: aqua;
	display: block;
	text-wrap: wrap;
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	margin-top: 20px;
}

strong {
	font-weight: bold;
	opacity: 1 !important;
	/* color: #89ffdc; */
	color: #c1fffb;
}
