/* .ql-editor {
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	padding: 0px;
	margin: 0px;
	overflow: visible;
}

.ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 70%;
	right: 0;

	object-fit: contain;
}

.cursor {
	cursor: pointer;
}

.ql-editor h1 {
	color: #eef2fc;
	font-size: xx-large;
}

.ql-editor h2 {
	color: #eef2fc;
	font-size: x-large;
	opacity: 0.9;
}

.ql-editor p {
	color: #eef2fc;
	font-size: medium;
	align-items: flex-end;
} */

.ql-whitepaper {
	color: #eef2fc;
	font-size: medium;
}
