/* Reset conflicting styles first */
.qleditor {
	font-family: "Poppins", sans-serif;
	color: #2b2c31;
}

/* Scope all Quill editor styles under our container */
.qleditor .ql-editor {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	padding: 10px;
	margin: 0;
	overflow: auto;
	min-height: 400px;
}

/* Consolidate duplicate selectors */
.qleditor .ql-editor h1,
.qleditor .ql-editor h2,
.qleditor .ql-editor h3,
.qleditor .ql-editor h4,
.qleditor .ql-editor h5,
.qleditor .ql-editor h6,
.qleditor .ql-editor pre,
.qleditor .ql-editor code,
.qleditor .ql-editor table,
.qleditor .ql-editor th,
.qleditor .ql-editor td,
.qleditor .ql-editor caption,
.qleditor .ql-editor figure,
.qleditor .ql-editor figcaption,
.qleditor .ql-editor cite {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	margin: 0;
}

.qleditor .ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 600px;
	right: 0;
	object-fit: contain;
	overflow: hidden;
}

.cursor {
	cursor: pointer;
}

/* Remove duplicate rules and keep only the scoped versions */

.descriptionStyle {
	font-family: "Poppins", sans-serif !important;
	color: #2b2c31;
	/* font-size: larger; */
	padding: 10px;
	margin: 0px;
	overflow: visible;
	min-height: 400px;
}

.qleditor * {
	font-family: "Poppins", sans-serif !important;
	color: #2b2c31 !important;
}

.ql-editor * {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
}

.qleditor p,
.qleditor span,
.qleditor div {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
}

.qleditor ul,
.qleditor ol,
.qleditor li {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
}

.qleditor a {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	text-decoration: underline;
}

.qleditor strong,
.qleditor b {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-weight: 900;
}

.qleditor em,
.qleditor i {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-style: italic;
}

.qleditor blockquote {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	border-left: 4px solid #2b2c31;
	padding-left: 16px;
	margin: 8px 0;
}

.qleditor pre,
.qleditor code {
	font-family: monospace !important;
	color: #2b2c31 !important;
}

.notes-editor-container {
	font-family: "Poppins", sans-serif;
	color: #2b2c31;
}

/* Quill editor specific styles */
.notes-editor-container .ql-editor {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	padding: 10px;
	margin: 0;
	overflow: auto;
	min-height: 400px;
}

/* Typography styles */
.notes-editor-container .ql-editor h1,
.notes-editor-container .ql-editor h2,
.notes-editor-container .ql-editor h3,
.notes-editor-container .ql-editor h4,
.notes-editor-container .ql-editor h5,
.notes-editor-container .ql-editor h6 {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	margin: 0;
}

/* Text content styles */
.notes-editor-container .ql-editor p,
.notes-editor-container .ql-editor span,
.notes-editor-container .ql-editor div {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-weight: 400;
	margin: 0;
	padding: 0;
}

/* List styles */
.notes-editor-container .ql-editor ul,
.notes-editor-container .ql-editor ol,
.notes-editor-container .ql-editor li {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
}

/* Links */
.notes-editor-container .ql-editor a {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	text-decoration: underline;
}

/* Text formatting */
.notes-editor-container .ql-editor strong,
.notes-editor-container .ql-editor b {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-weight: 900;
}

.notes-editor-container .ql-editor em,
.notes-editor-container .ql-editor i {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	font-style: italic;
}

/* Blockquotes */
.notes-editor-container .ql-editor blockquote {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31 !important;
	border-left: 4px solid #2b2c31;
	padding-left: 16px;
	margin: 8px 0;
}

/* Code blocks */
.notes-editor-container .ql-editor pre,
.notes-editor-container .ql-editor code {
	font-family: monospace !important;
	color: #2b2c31 !important;
}

/* Images */
.notes-editor-container .ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 600px;
	right: 0;
	object-fit: contain;
	overflow: hidden;
}

/* Utility classes */
.cursor {
	cursor: pointer;
}

/* Update list styles */
.notes-editor-container .ql-editor ul,
.notes-editor-container .ql-editor ol {
	margin: 0.5em 0;
	padding-left: 1.5em;
}

.notes-editor-container .ql-editor li {
	margin: 0;
	padding: 0;
}

/* Ensure proper spacing between list items */
.notes-editor-container .ql-editor li + li {
	margin-top: 0.25em;
}

/* Remove extra spacing around lists */
.notes-editor-container .ql-editor ul p,
.notes-editor-container .ql-editor ol p {
	margin: 0;
}
