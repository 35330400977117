.ql-overflow {
	/* overflow: visible !important; */
	/* height: auto !important; */
	font-family: "Poppins", sans-serif !important;
	color: #eef2fc;
	text-wrap: wrap !important;
	padding: 0px;
	margin: 0px;
}

.ql-overflow img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 70%;

	object-fit: contain;
	max-width: 600px;
	max-height: 90%;
}

.ql-overflow h1 strong {
	padding: 0px;
	margin: 0px;

	font-size: var(--h1);
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
}

h1 {
	padding: 0px;
	margin: 0px;
	font-size: var(--h1);
	letter-spacing: 0px;
	line-height: 1;
	text-wrap: wrap;
	width: 100%;
}

.ql-overflow h2 {
	padding: 0px;
	margin: 0px;
	font-size: var(--h2);
	line-height: -10px;
	line-height: 1.2;
	text-wrap: wrap;
}

.ql-overflow p ul ol li {
	padding: 0;
	margin: 0;
	/* font-size: 15px; */
	font-size: var(--pp);
	margin-bottom: 10px;
	text-wrap: wrap;
	flex: auto;
	line-break: anywhere;
}

.ql-overflow a {
	font-size: 12px;
	color: #000;
	padding: 10px;
	border-radius: 10px;
	background-color: aqua;
	display: block;
	text-wrap: wrap;
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	/* margin-top: 20px; */
}
