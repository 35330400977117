.ql-editor {
	font-family: "Poppins", sans-serif !important;

	/* font-family: "Gotham", sans-serif !important; */
	color: #2b2c31;
	/* font-size: larger; */
	padding: 10px;
	margin: 0px;
	overflow: scroll;
	min-height: 400px;
}

.ql-editor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 70%;
	right: 0;

	object-fit: contain; /* Adjust based on how you want the image to fit */
}

.cursor {
	cursor: pointer;
}

/* .ql-editor h1 {
	color: #2b2c31;
	font-size: xx-large;
}

.ql-editor h2 {
	color: #2b2c31;
	font-size: x-large;
	opacity: 0.9;
}

.ql-editor p {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	align-items: flex-end;
} */

.descriptionStyle {
	font-family: "Poppins", sans-serif !important;
	color: #2b2c31;
	/* font-size: larger; */
	padding: 10px;
	margin: 0px;
	overflow: visible;
	min-height: 400px;
}

.qleditor {
	font-family: "Poppins", sans-serif !important;
	color: #2b2c31 !important;
}

.qleditor h1 {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	margin: 0px;
}

.qleditor h2 {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	margin: 0px;
}

.qleditor p {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
	font-weight: 400;
	margin: 0px;
}

.qleditor ul {
	font-family: "Gotham", sans-serif !important;
	color: #2b2c31;
}

.qleditor img {
	display: block;
	width: 100%;
	height: auto;
	max-height: 600px;
	right: 0;
	object-fit: contain;
	overflow: hidden;
}

.qleditor strong {
	font-family: "Gotham", sans-serif !important;
	color: #0c122e;
	font-weight: 900;
}
