.retry-button {
	background-color: #4caf50;
	color: #fff;
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 10px;
}

.control-button {
	padding: 8px 16px;
	border-radius: 4px;
	border: none;
	cursor: pointer;
	font-weight: 500;
	min-width: 100px;
}

.control-button.pause {
	background-color: #ff4444;
	color: white;
}

.control-button.resume {
	background-color: #4caf50;
	color: white;
}

.retry-button:hover,
.control-button:hover {
	opacity: 0.9;
}

.retry-button:active,
.control-button:active {
	transform: scale(0.98);
}
